<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-center>
        <span class="itemHeading">Probation Review Form</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-form ref="form" @submit.prevent="submitForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>
                  Rating: Very Good (1) &nbsp;&nbsp; Good (2) &nbsp;&nbsp; Below Average (3) &nbsp;&nbsp; Poor
                  (4)
                </p>
              </v-col>
            </v-row>
            <v-row v-for="(competency, index) in competencies" :key="index">
              <v-col cols="12" class="text-left">
                <span>{{ index + 1 }}.&nbsp;{{ competency.competency }}</span>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="competency.rating"
                  :items="ratings"
                  label="-select-"
                  outlined
                  :rules="[(v) => !!v || 'Rating is required']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="comments"
                  label="Overall comments/ Remarks"
                  outlined
                  :rules="[(v) => !!v || 'Remarks are required']"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-left">
                <p>Recommendation (Please Tick One):</p>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="isCleared"
                  label="Recommended for clearing of probation period"
                  @change="validateCheckbox('isCleared')"
                ></v-checkbox>
                <v-checkbox
                  v-model="isExtension"
                  label="Recommended for extension of the probation for"
                  @change="validateCheckbox('isExtension')"
                ></v-checkbox>
                <v-select
                  v-if="isExtension"
                  v-model="extensionMonths"
                  :items="months"
                  label="Number of months"
                  outlined
                ></v-select>
                <v-checkbox
                  v-model="notCleared"
                  label="Not recommended for clearance / extension of probation"
                  @change="validateCheckbox('notCleared')"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row wrap justify="center">
              <v-col cols="12" sm="5" class="text-center">
                <v-btn v-if="Object.keys(data).length === 0"
                  color="#005f32"
                  dark
                  tile
                  large
                  class="rounded-xl"
                  block
                  @click="submitForm"
                >
                  Submit
                </v-btn>
                <v-btn v-else
                  color="#005f32"
                  dark
                  tile
                  large
                  class="rounded-xl"
                  block
                  @click="updateForm"
                >
                  Update
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      data: {},
      rating: null,
      ratings: [1, 2, 3, 4],
      comments: "",
      isCleared: false,
      isExtension: false,
      extensionMonths: null,
      notCleared: false,
      months: Array.from({ length: 12 }, (_, i) => i + 1),
      competencies: [
        { competency: "Understanding of the organization", rating: null },
        { competency: "Understanding of the project", rating: null },
        {
          competency: "Loyalty & Honesty",
          rating: null,
        },
        { competency: "Time Management", rating: null },
        { competency: "Eagerness to learn", rating: null },
        { competency: "Team working skills", rating: null },
        { competency: "Quality of work", rating: null },

        { competency: "Punctuality", rating: null },
        { competency: "Sense of ownership", rating: null },
        { competency: "Level of maturity", rating: null },
        { competency: "Technical Skills", rating: null },
      ],
    };
  },

  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/probationreviewform/view",
        method: "POST",
        data: {
          employeeId: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.data = response.data.data;
            this.competencies[0].rating = this.data.Understandorganization;
            this.competencies[1].rating = this.data.Understandproject;
            this.competencies[2].rating = this.data.LoyaltyHonesty;
            this.competencies[3].rating = this.data.TimeManagement;
            this.competencies[4].rating = this.data.Eagernesstolearn;
            this.competencies[5].rating = this.data.Teamworkingskills;
            this.competencies[6].rating = this.data.Qualityofwork;
            this.competencies[7].rating = this.data.Punctuality;
            this.competencies[8].rating = this.data.SenseOwnership;
            this.competencies[9].rating = this.data.LevelMaturity;
            this.competencies[10].rating = this.data.TechnicalSkills;
            this.comments = this.data.Remarks;
            this.isCleared = this.data.isCleared;
            this.isExtension = this.data.isExtension;
            this.extensionMonths = this.data.ExtensionNoOfMonths;
            this.notCleared = this.data.notCleared;
          } else {
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validateCheckbox(changedCheckbox) {
      if (changedCheckbox === "isCleared" && this.isCleared) {
        this.isExtension = false;
        this.notCleared = false;
        this.extensionMonths = null;
      } else if (changedCheckbox === "isExtension" && this.isExtension) {
        this.isCleared = false;
        this.notCleared = false;
      } else if (changedCheckbox === "notCleared" && this.notCleared) {
        this.isCleared = false;
        this.isExtension = false;
        this.extensionMonths = null;
      }
    },
    submitForm() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.isCleared || this.isExtension || this.notCleared) {
          this.submitData();
        } else {
          this.msg = "Please select at least one recommendation checkbox.";
          this.showsnackbar = true;
        }
      }
    },
    updateForm() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.isCleared || this.isExtension || this.notCleared) {
          this.updateData();
        } else {
          this.msg = "Please select at least one recommendation checkbox.";
          this.showsnackbar = true;
        }
      }
    },
    updateData() {
      this.appLoading = true;
      axios({
        url: "probationreviewform/AtoD/update",
        method: "POST",
        data: {
          employeeId: this.$route.query.id,
          Understandorganization: this.competencies[0].rating,
          Understandproject: this.competencies[1].rating,
          LoyaltyHonesty: this.competencies[2].rating,
          TimeManagement: this.competencies[3].rating,
          Eagernesstolearn: this.competencies[4].rating,
          Teamworkingskills: this.competencies[5].rating,
          Qualityofwork: this.competencies[6].rating,
          Punctuality: this.competencies[7].rating,
          SenseOwnership: this.competencies[8].rating,
          LevelMaturity: this.competencies[9].rating,
          TechnicalSkills: this.competencies[10].rating,
          Remarks: this.comments,
          isCleared: this.isCleared,
          isExtension: this.isExtension,
          ExtensionNoOfMonths: this.extensionMonths,
          notCleared: this.notCleared,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData(); 
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submitData() {
      this.appLoading = true;
      axios({
        url: "/probationreviewform/AtoD/add",
        method: "POST",
        data: {
          employeeId: this.$route.query.id,
          Understandorganization: this.competencies[0].rating,
          Understandproject: this.competencies[1].rating,
          LoyaltyHonesty: this.competencies[2].rating,
          TimeManagement: this.competencies[3].rating,
          Eagernesstolearn: this.competencies[4].rating,
          Teamworkingskills: this.competencies[5].rating,
          Qualityofwork: this.competencies[6].rating,
          Punctuality: this.competencies[7].rating,
          SenseOwnership: this.competencies[8].rating,
          LevelMaturity: this.competencies[9].rating,
          TechnicalSkills: this.competencies[10].rating,
          Remarks: this.comments,
          isCleared: this.isCleared,
          isExtension: this.isExtension,
          ExtensionNoOfMonths: this.extensionMonths,
          notCleared: this.notCleared,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData(); 
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>